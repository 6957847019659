import {Container} from "react-bootstrap";

const Datenschutz = () => {

    return (
        <Container>
            <h2> Bearbeitung</h2>
        </Container>
    )
}
export default Datenschutz;