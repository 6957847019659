
import {Button, Container, Image} from "react-bootstrap";

function ImageWithButton() {
    return (

        <div style={{marginTop : "300px", position: 'relative', display: 'inline-block' }}>
            <img src="/contact-us.jpg" alt="Your Image" style={{ width: '100%' }} />
            <Button href="/contact" variant="primary" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgba(255, 255, 255, 0.5)', color: '#000', border: 'none' }}>
                Contact us
            </Button>
        </div>
    );
}
const About = () => {
    return (
        <Container>
            <div className="d-flex">
                <div className="w-50">
                    <Image src="/rene.jpeg" className="w-100 h-100" fluid/>
                </div>
                <div className="w-50 d-flex align-items-center ms-3">
                    <div>
                        <h2>Über mich</h2>
                        <p>Als erfahrener Maler- und Lackierermeister mit 25-Jahren an Expertise verfüge ich über umfassende
                            Erfahrung in der Branche. Ich stehe Ihnen als kompetenter Partner für alle Ihre Maler- und
                            Lackierarbeiten zur Verfügung und unterstütze Sie gerne mit meinem Fachwissen und meiner
                            Einsatzbereitschaft. </p>

                    </div>
                </div>
            </div>


            {/*<div style={{marginTop: "100px"}}>
                <h2>Wir schaffen Ihren ganz persönlichen Raum.</h2>
                <div className="row mt-3">
                    <div className="col-sm-6 col-md-3">
                        <div className="d-flex flex-column align-items-center">
                            <Image
                                src="https://via.placeholder.com/150"
                                roundedCircle
                                className="m-3"
                            />
                            <h3>Perfection</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet
                                nisl tempus
                                convallis quis ac lectus. Nulla quis</p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="d-flex flex-column align-items-center">
                            <Image
                                src="https://via.placeholder.com/150"
                                roundedCircle
                                className="m-3"
                            />
                            <h3>Quality</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet
                                nisl tempus
                                convallis quis ac lectus. Nulla quis</p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="d-flex flex-column align-items-center">
                            <Image
                                src="https://via.placeholder.com/150"
                                roundedCircle
                                className="m-3"
                            />
                            <h3>Customer satisfaction</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet
                                nisl tempus
                                convallis quis ac lectus. Nulla quis</p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="d-flex flex-column align-items-center">
                            <Image
                                src="https://via.placeholder.com/150"
                                roundedCircle
                                className="m-3"
                            />
                            <h3>Technology</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet
                                nisl tempus
                                convallis quis ac lectus. Nulla quis</p>
                        </div>
                    </div>
                </div>
            </div>*/}




            {/* <ImageWithButton />*/}


        </Container>


    )
        ;
}

export default About;
