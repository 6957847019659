//create header component
import React from 'react';
import {Navbar, Nav, Container, NavDropdown, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const HeaderComponent = () => {
    return (
        <Navbar bg="light" expand="lg" className="w-100">
            <Container>
                <Navbar.Brand href="/" style={{width:"500px"}} className="  d-flex justify-content-center m-0">
                    <Image src="/logo.png"  className="w-50"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="/about" className="fs-4 " >  <p className="h2 w-100"> Über mich </p> </Nav.Link>
                        <Nav.Link href="/services" className="fs-4"> <p className="h2">Dienstleistungen</p></Nav.Link>
                        <Nav.Link href="/portfolios" className="fs-4"> <p className="h2">Portfolios</p></Nav.Link>
                        {/* <Nav.Link href="/reservation" className="fs-4">Reservierung</Nav.Link>*/}
                        <Nav.Link href="/faq" className="fs-4"> <p className="h2">FAQ</p></Nav.Link>

                        <Nav.Link href="/contact" className="fs-4"> <p className="h2">Kontakt</p></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default HeaderComponent;