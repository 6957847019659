
import React from 'react';
import {Button, Col, Container, FigureCaption, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FadeInWhenVisible, FramerHoverButton, FramerHoverZoomIn} from "./FramerMotionCollection";
import {motion, px} from "framer-motion";

function ImageWithText() {

    return (

        <div style={{marginTop : "200px", position: 'relative', display: 'inline-block' }}>
            <img src="https://via.placeholder.com/1200x600" alt="Your Image" style={{ width: '100%' }} />
            <div className="text-center" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                <h1 className="display-4">Maler & Lackiere</h1>
                <p className="lead">Professionelle Maler- und Lackierarbeiten</p>
            </div>
        </div>
    );
}

//button hover effect


const Main = () => {

    const infoList = [
        {
            id: 1,
            title: "Qualität",
            description: "Wir verwenden nur hochwertige Farben und Materialien, um sicherzustellen, dass Ihre Räume makellos aussehen.",
            link : "https://img.icons8.com/ios/100/guarantee--v1.png"
        },
        {
            id: 2,
            title: "Zuverlässigkeit",
            description: "Wir halten uns an unsere Termine und liefern pünktlich.",
            link : "https://img.icons8.com/ios/100/trust--v1.png"
        },
        {
            id: 3,
            title: "Professionalität",
            description: "Unsere erfahrenen Maler und Lackierer liefern qualitativ hochwertige Arbeit.",
            link : "https://img.icons8.com/parakeet-line/96/full-tool-storage-box-.png"
        },
        {
            id: 4,
            title: "Kundenorientierung",
            description: "Wir sind stolz darauf, exzellenten Kundenservice zu bieten.",
            link : "https://img.icons8.com/ios/100/user--v1.png"
        }
    ];

    return (
        <Container>

            {/*<ImageWithText/>*/}
            <Row>
                <Col md={12}>
                    <h1 className="mb-4"> Malermeister Rene Franzoi </h1>
                    <h4 className="mb-4"> Professionelles Maler- und Lackiererunternehmen in Königslutter am Elm</h4>
                    <h4 className="mb-4"> Fachgerechte und hochwertige Malerarbeiten seit (1998) </h4>

                    <p className="lead mb-4">Herzlich willkommen! Sie wollen etwas Farbe in Ihr Zuhause bringen? Bei uns
                        sind Sie an der
                        richtigen Adresse für saubere und zügige Malerarbeiten in Königslutter und Umgebung. Wir sorgen
                        dafür, dass alle Arbeiten Ihren Wünschen entsprechend erledigt werden.</p>

                    <h4 className="mb-4"> Vom Kleinauftrag bis hin zu komplexen Projekten </h4>
                    <p className="lead mb-4">
                       Wir haben uns auf die Renovierung von Wohn- und
                        Geschäftsräumen spezialisiert, Eine exzellente Arbeit sieht für uns an erster Stelle. Wir verwenden
                        ausschließlich hochwertige Farben und Materialien, um sicherzustellen, dass Ihre Räume nicht nur
                        schön aussehen, sondern Sie auch langfristig Freude daran haben.
                    </p>
                    <p className="lead mb-4">
                        Bei uns erwartet Sie das gesamte Spektrum von Malerarbeiten, Tapezierarbeiten und
                        Bodenverlegearbeiten – vom Kleinauftrag bis hin zu komplexen Projekten. Durch den Einsatz
                       von z.B. Spachteltechnik, Wischtechnik,Dekorfliesen und anderer verschiedenster Gestaltungstechniken erfüllen wir Ihre Wünsche.
                       <br/> Alle Leistungen rund um die Malereiarbeiten erhalten Sie bei uns aus einer Hand.
                    </p>
                    <p className="lead mb-4">
                        Sie wissen noch nicht welche Farbe sich perfekt in das Gesamtbild Ihres Zuhauses einfügen könnte?
                        Gerne stehen wir auch beratend an Ihrer Seite.
                        Kontaktieren Sie uns noch heute für ein kostenloses Angebot und lassen Sie Ihren
                        Wohn- oder Geschäftsbereich in neuem Glanz erstrahlen!
                    </p>
                    <p className="lead mb-4">
                        Wir sind stolz darauf, unseren Kunden qualitativ hochwertige Arbeit bieten zu können und
                        herausragenden Kundenservice zu gewährleisten.
                    </p>
                    <h4 className="mb-4">Ihre Ideen setzen wir in die Tat um! </h4>


                    <Button href="/contact" variant="primary">Kontaktieren Sie uns</Button>
                </Col>
            </Row>

            <p>







            </p>
            {/*<Row>
                <Col md={12}>
                    <h2 className="mb-4">Willkommen bei Malermeister-ReneFranzoi - Professionelles Maler- und Lackierunternehmen in Königslutter am Elm</h2>
                    <p className="lead mb-4">Herzlich willkommen! Ich bin Rene Franzoi ! Wir sind ein führendes Maler- und Lackierunternehmen, das sich auf die Renovierung von Wohn- und Geschäftsräumen in Königslutter am Elm und Umgebung spezialisiert hat. Unsere breite Palette von Dienstleistungen umfasst professionelle Innen- und Außenmalerei sowie die Lackierung von Möbeln und anderen Oberflächen.</p>
                    <p className="mb-4">Unsere erfahrenen Maler und Lackierer verwenden ausschließlich hochwertige Farben und Materialien, um sicherzustellen, dass Ihre Räume nicht nur schön aussehen, sondern auch lange halten. Wir sind stolz darauf, unseren Kunden qualitativ hochwertige Arbeit zu bieten und exzellenten Kundenservice zu gewährleisten.</p>
                    <p className="mb-4">Ob es sich um ein kleines Renovierungsprojekt oder eine groß angelegte Renovierung handelt, wir stehen Ihnen mit unserem Fachwissen und unserer Erfahrung zur Seite. Kontaktieren Sie uns noch heute für ein kostenloses Angebot und lassen Sie uns gemeinsam Ihren Wohn- oder Geschäftsbereich in neuem Glanz erstrahlen!</p>
                    <Button href="/contact" variant="primary">Kontaktieren Sie uns</Button>
                </Col>
            </Row>
*/}

            <Row className="justify-content-center">
                {infoList.map((info, index) => (
                    <Col key={index} xs={12} md={6} lg={3} className="mb-4" style={{marginTop: "200px"}}>
                        <FadeInWhenVisible>
                            <div className="d-flex flex-column align-items-center">
                                <Image
                                    src={info.link}
                                    fluid
                                    roundedCircle
                                    className="m-3"
                                    style={{width: "150px", height: "150px", objectFit: "cover"}}
                                />
                                <h3>{info.title}</h3>
                                <p>{info.description}</p>
                            </div>
                        </FadeInWhenVisible>
                    </Col>
                ))}
            </Row>


            <h2 style={{marginTop: "200px"}}>Unsere Dienstleistungen</h2>
            <FadeInWhenVisible>
                <Row className=" justify-content-center align-content-center" style={{marginTop: "50px"}}>
                    <Col xs={12} md={6} lg={3} className="text-center mb-3 mt-5">
                        <FramerHoverZoomIn>
                            <Image className="w-75" src="/wand-example.jpeg" alt="Innenbereicharbeit"/>
                        </FramerHoverZoomIn>
                        <p className="mt-3">Maler - und Tapezierarbeiten im Innenbereich</p>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="text-center mb-3 mt-5">
                        <FramerHoverZoomIn>
                            <Image className="w-75" src="/pvc-example.jpeg" alt="Your Image"/>
                        </FramerHoverZoomIn>
                        <p className="mt-3">Fußbodenarbeiten im Innenberiech</p>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="text-center mb-3 mt-5">
                        <FramerHoverZoomIn>
                            <Image className="w-75" src="/putzarbeit-example.jpg" alt="Your Image"/>
                        </FramerHoverZoomIn>
                        <p className="mt-3">Putzarbeiten im Innen- und Außenbereich</p>
                    </Col>
                </Row>

                <div className="mt-5 d-flex justify-content-center align-content-center">
                    <motion.button
                        className={"mt-5 w-50 w-md-25 rounded-1 "}
                        whileHover={{scale: 1.2}}
                        whileTap={{scale: 0.9}}
                        style={{
                            background: "black",
                            border: "none",
                            height: "50px",
                            color: "white",
                            fontSize: "20px",
                            whiteSpace: "normal", // 텍스트 줄 바꿈 설정
                            overflowWrap: "break-word" // 텍스트가 넘칠 때 줄 바꿈
                        }}
                        onClick={() => {
                            window.location.href = "/services";
                        }}
                    >
                        <p className={"m-0"}>Mehr Erfahren </p>
                    </motion.button>

                </div>


            </FadeInWhenVisible>


            <h2 className="mt-5">Öffnungszeiten</h2>
            <div className="p-3 bg-body-secondary text-center">
                <h3>Dienstag - Freitag: 9:00 - 18:00 Uhr</h3>
                <h4>Samstag - Montag: Geschlossen</h4>


            </div>

            <iframe
                width="100%"
                height="300"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=de&amp;q=Gross steinum+(Maler%20%26%20Lackiere)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                className="mt-5"
            />
            <div className="text-center mt-5">
                <h2>Kontaktieren Sie uns</h2>
                <div className="mt-5 d-flex justify-content-center align-content-center">
                    <motion.button
                        className={"mt-5 w-25 w-md-25 rounded-1 "}
                        whileHover={{scale: 1.2}}
                        whileTap={{scale: 0.9}}
                        style={{
                            background: "black",
                            border: "none",
                            height: "50px",
                            color: "white",
                            fontSize: "20px",
                            whiteSpace: "normal", // 텍스트 줄 바꿈 설정
                            overflowWrap: "break-word" // 텍스트가 넘칠 때 줄 바꿈
                        }}
                        onClick={() => {
                            window.location.href = "/contact";
                        }}
                    >
                        <p className={"m-0"}> Kontakt </p>
                    </motion.button>

                </div>

            </div>


        </Container>
    );

}

export default Main;