
import React from 'react';
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

const FooterComponent = () => {
    return (

            <Navbar bg="black" className="pt-5 w-100">
                <Container >
                    <Row className="text-white w-100">

                        <Col>
                            <h5 className="">Adresse</h5>
                            <p className="text-white-50 m-0">Am Kirchberg 5</p>
                            <p className="text-white-50 m-0">Groß Steinum</p>
                            <p className="text-white-50 m-0">Königslutter am elm </p>
                        </Col>
                        <Col className="p-0">
                        <h5 className="">Rechtliches</h5>
                                <Nav.Link href="/Datenschutz" className="text-white-50"> Datenschutz</Nav.Link>
                                <Nav.Link href="/Impressum" className="text-white-50">Impressum</Nav.Link>
                                <Nav.Link href="/AGB" className="text-white-50">AGB</Nav.Link>

                            </Col>
                        <Col>
                            <h5>Kontakt</h5>
                            <p className="text-white-50 m-0">Tel: (+49) 017670197633</p>
                            <h5 className=" mt-3">Email</h5>
                            <p className=" mt-1 "><a className="text-decoration-none text-white-50" href="mailto:example@example.com">
                                malermeisterfranzoi@gmail.com</a> </p>

                        </Col>
                    </Row>



                </Container>
            </Navbar>

    );


}
export default FooterComponent;