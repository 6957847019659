import {BrowserRouter, Route, Routes} from "react-router-dom";

import UserApp from "./UserComponent/UserApp";
import HeaderComponent from "./UserComponent/HeaderComponent";
import {Container} from "react-bootstrap";
import Main from "./UserComponent/Main";
import About from "./UserComponent/About";
import FAQ from "./UserComponent/FAQ";
import Portfolios from "./UserComponent/Portfolios";
import Service from "./UserComponent/Service";
import Contact from "./UserComponent/Contact";
import AGB from "./UserComponent/Rechtliches/AGB";
import Impressum from "./UserComponent/Rechtliches/Impressum";
import Datenschutz from "./UserComponent/Rechtliches/Datenschutz";
import FooterComponent from "./UserComponent/FooterComponent";






function App() {
  return (

      <BrowserRouter>


          <div className="UserApp">

              <HeaderComponent/>
              <Container className="min-vh-100 mt-5 mb-5">
                  <Routes>

                      <Route exact path='/' element={<Main/>}/>
                      <Route exact path='/about' element={<About/>}/>
                      <Route exact path='/faq' element={<FAQ/>}/>

                      <Route path='/portfolios' element={<Portfolios/>}/>

                      <Route path='/services' element={<Service/>}/>

                      <Route path='/contact' element={<Contact/>}/>

                      <Route path='/AGB' element={<AGB/>}/>
                      <Route path='/Impressum' element={<Impressum/>}/>
                      <Route path='/Datenschutz' element={<Datenschutz/>}/>
                  </Routes>
              </Container>


              <FooterComponent/>
          </div>


      </BrowserRouter>
  );
}

export default App;
