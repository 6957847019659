import { Button, Card, Carousel, Col, Container, Row } from "react-bootstrap";
import { useState } from "react";

const Portfolios = () => {
    const [selectedProjectIndex, setSelectedProjectIndex] = useState(null);

    const projects = [
        {
            id: 1,
            title: "Projekt 1",
            description: "04. 4. 2024 - 09. 4. 2024",
            image: "/portfolio/ProjektOne/1.jpg",
            resultImages: [
                "/portfolio/ProjektOne/1.jpg",
                "/portfolio/ProjektOne/2.jpg",
                "/portfolio/ProjektOne/3.jpg",
                // 다른 사진들 추가
            ],
            resultDescriptions: [
                "Before",
                "einmal gestriechen",
                "after",
                // 다른 설명들 추가
            ]
        },
        {
            id: 2,
            title: "Projekt 2",
            description: "10. 4. 2024 - 11. 4. 2024",
            image: "/portfolio/ProjektTwo/1.jpg",
            resultImages: [
                "/portfolio/ProjektTwo/1.jpg",
                "/portfolio/ProjektTwo/2.jpg",
                // 다른 사진들 추가
            ],
            resultDescriptions: [
                "Before",
                "after",
                // 다른 설명들 추가
            ]
        },
        {
            id: 3,
            title: "Projekt 3",
            description: "15. 4. 2024 - 25. 4. 2024",
            image: "/portfolio/ProjektThree/1.jpg",
            resultImages: [
                "/portfolio/ProjektThree/1.jpg",
                "/portfolio/ProjektThree/2.jpg",
                "/portfolio/ProjektThree/3.jpg",
                // 다른 사진들 추가
            ],
            resultDescriptions: [
                "Before",
                "after",
                // 다른 설명들 추가
            ]
        }
        // 다른 프로젝트들 추가
    ];

    const handleShowResult = (projectIndex) => {
        if (selectedProjectIndex === projectIndex) {
            setSelectedProjectIndex(null); // 클릭한 프로젝트가 이미 선택된 경우 닫기
        } else {
            setSelectedProjectIndex(projectIndex); // 다른 프로젝트를 클릭한 경우 새 프로젝트 선택
        }
    };

    return (
        <Container>
            <h1 className="mt-5 mb-4">Unser Portfolio</h1>
            <Row>
                {projects.map((project, index) => (
                    <Col key={project.id} md={4} className="mb-4">
                        <Card>
                            <Card.Img variant="top" src={project.image} />
                            <Card.Body>
                                <Card.Title>{project.title}</Card.Title>
                                <Card.Text>{project.description}</Card.Text>
                                <Button
                                    variant="primary"
                                    onClick={() => handleShowResult(index)}
                                >
                                    {selectedProjectIndex === index ? "Schließen" : "Mehr"}
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            {selectedProjectIndex !== null && (
                <div className="mt-5 mb-5">
                    <h2>{projects[selectedProjectIndex].title} Ergebnisse von Uns</h2>
                    <Carousel className="w-50 mt-5">
                        {projects[selectedProjectIndex].resultImages.map((resultImage, index) => (
                            <Carousel.Item key={index}>
                                <img className="d-block w-100" src={resultImage} alt={projects[selectedProjectIndex].title} />
                                <Carousel.Caption>
                                    <p>{projects[selectedProjectIndex].resultDescriptions[index]}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            )}
        </Container>
    );
};

export default Portfolios;
