import React, { useState } from 'react';
import {Form, Row, Col, Button, Spinner, Container} from 'react-bootstrap';
import axios from "axios";
import {motion} from "framer-motion";




function Loading({ children }) {
    return (
        <Spinner size="sm" className="ms-3" animation="border" role="status">
            <span className="visually-hidden">{children}</span>
        </Spinner>
    );
}

function ContactForm() {
    const [email, setEmail] = useState('');
    const [vorname, setVorname] = useState('');
    const [nachname, setNachname] = useState('');
    const [titel, setTitel] = useState('');
    const [content, setContent] = useState('');
    const [errors, setErrors] = useState({});

    const [isSubmitting,setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);

    const validateForm = () => {
        const errors = {};
        if (!email) {
            errors.email = 'Email is required';
        }
        // Add more validation for other fields if necessary

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {

        e.preventDefault();
        if (validateForm()) {
            setIsSubmitting(true);
            setLoading(true);

            axios.post('https://port-0-java-springboot-1pgyr2mlvmu2cd2.sel5.cloudtype.app/api/contact/post', {
                "contUsrEmail": email,
                "contUsrVorname": vorname,
                "contUsrNachname": nachname,
                "contTitel": titel,
                "contText": content
            })
                .then(response => {
                    setLoading(false);
                    if(response.data.SUCCESS === true){

                        alert('Danke! wir melden uns Ihnen bald ! ');
// eslint-disable-next-line no-restricted-globals
                        location.href = "/";
                    }
                    else{
                        alert("failed to save :(")
                        setIsSubmitting(false);
                    }

                })
                .catch(error => {
                    console.error('Failed to save form data:', error);
                });
        }
    };

    return (
        <Container>


            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email Addresse</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        name="conUsrEmail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.email}
                    </Form.Control.Feedback>
                </Form.Group>

                <Row className="mb-3">
                    <Col>
                        <Form.Group className="mb-3" controlId="vorname">
                            <Form.Label>Vorname</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Vorname"
                                name="conUsrVorname"
                                value={vorname}
                                onChange={(e) => setVorname(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="nachname">
                            <Form.Label>Nachname</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nachname"
                                name="conUsrNachname"
                                value={nachname}
                                onChange={(e) => setNachname(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group className="mb-3" controlId="titel">
                    <Form.Label>Titel</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Titel"
                        name="contTitel"
                        value={titel}
                        onChange={(e) => setTitel(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="content">
                    <Form.Label>Kontent</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={10}
                        name="contText"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={isSubmitting}>
                    Absenden
                    {loading ? <Loading className="ms-5 "></Loading> : null}
                </Button>

            </Form>
        </Container>

    );
}

export default ContactForm;
